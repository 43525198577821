import { getCookie } from "./getCookie";

export default (generateDeeplinkFunc) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  const cookiebanner = document.getElementById("cookie-banner");
  let brickwiseCookieConsentgtagObject = {
    ad_storage: false,
    ad_user_data: false,
    ad_personalization: false,
    analytics_storage: false,
    personalization_storage: false,
    functionality_storage: true
  };
  const sendConsentToTagManager = (
    { ad_storage, ad_user_data, analytics_storage, ad_personalization, personalization_storage, functionality_storage },
    refireTags
  ) => {
    gtag("consent", "update", {
      ad_storage: ad_storage ? "granted" : "denied",
      ad_user_data: ad_user_data ? "granted" : "denied",
      ad_personalization: ad_personalization ? "granted" : "denied",
      analytics_storage: analytics_storage ? "granted" : "denied",
      personalization_storage: personalization_storage ? "granted" : "denied",
      functionality_storage: functionality_storage ? "granted" : "denied",
    });
    if (refireTags) {
      dataLayer.push({ event: "refireTags" });
    }
  };

  function setBrickwiseCookieConsent(options) {
    let cookieConent = JSON.stringify(options);
    const d = new Date();
    d.setTime(d.getTime() + 180 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
      "brickwiseCookieConsentgGtm" + "=" + cookieConent + ";" + expires + ";path=/";
  }

  const brickwiseCookieConsentgGtm = getCookie("brickwiseCookieConsentgGtm");
  if (!brickwiseCookieConsentgGtm) {
    cookiebanner.style.display = "block";
  } else {
    brickwiseCookieConsentgtagObject = JSON.parse(brickwiseCookieConsentgGtm);
    sendConsentToTagManager(brickwiseCookieConsentgtagObject, true);
  }

  // Interactions with the cookie banner
  const allClick = document.getElementById("btn_cookie_all");
  const noneClick = document.getElementById("btn_cookie_none");
  const specificClick = document.getElementById("btn_cookie_save");
  allClick.addEventListener("click", handleAllClick, false);
  noneClick.addEventListener("click", handleSpecificClick, false);
  specificClick.addEventListener("click", handleSpecificClick, false);

  function handleAllClick() {
    brickwiseCookieConsentgtagObject = {
      ad_storage: true,
      analytics_storage: true,
      personalization_storage: true,
    };
    setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
    sendConsentToTagManager(brickwiseCookieConsentgtagObject, true);
    document.getElementById("cookie-banner").style.display = "none";
    generateDeeplinkFunc();
  }

  function handleSpecificClick() {
    const performance = document.getElementById("performance-checkbox").checked;
    const marketing = document.getElementById("advertisment-checkbox").checked;
    if (performance && marketing) {
      brickwiseCookieConsentgtagObject = {
        ad_storage: true,
        ad_user_data: true,
        ad_personalization: true,
        analytics_storage: true,
        personalization_storage: true,
        functionality_storage: true,
      };
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      sendConsentToTagManager(brickwiseCookieConsentgtagObject, true);
    } else if (marketing && !performance) {
      brickwiseCookieConsentgtagObject.ad_storage = true;
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      sendConsentToTagManager(brickwiseCookieConsentgtagObject, true);
    } else if (!marketing && performance) {
      brickwiseCookieConsentgtagObject.analytics_storage = true;
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      sendConsentToTagManager(brickwiseCookieConsentgtagObject, true);
    } else if (!marketing && !performance) {
      brickwiseCookieConsentgtagObject.functionality_storage = false;
      setBrickwiseCookieConsent(brickwiseCookieConsentgtagObject);
      sendConsentToTagManager(brickwiseCookieConsentgtagObject, true);
    } else {
    }
    document.getElementById("cookie-banner").style.display = "none";
    generateDeeplinkFunc();
  }
};
