export default (environment) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    personalization_storage: "denied",
    functionality_storage: "granted"
  });
  if (environment === "development") {
    // Change this to Development TGM when ready
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src =
        "https://www.googletagmanager.com/gtm.js?id=" +
        i +
        dl +
        "&gtm_auth=7P0RcZLF65toilJl38qUpg&gtm_preview=env-161&gtm_cookies_win=x";
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-WFG8ZDC");
  } else {
    // Change this to Development TGM when ready
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src =
        "https://www.googletagmanager.com/gtm.js?id=" +
        i +
        dl +
        "&gtm_auth=UENQKo3Zw6rktYePnaJ3xw&gtm_preview=env-1&gtm_cookies_win=x";
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-WFG8ZDC");
  }
};
